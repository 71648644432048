import React from 'react';
import { Link } from 'gatsby';
import { TwitterBlack, FacebookBlack, InstagramBlack, LinkedinBlack } from '../icon/icon';
import PRSLogo from "../../images/prs.png";
import ARLAPMLogo from "../../images/arla-property-mark.png";
import NAEAPMLogo from "../../images/property-mark.png";

const FooterBottom = ({bottomContent}) => {
  return (
    <div className="footer-bottom">    
         <div className="footer-bottom-left">
         <div className="socials">
              <ul>
                <li><a href={bottomContent?.Twitter_Link} target="_blank"  rel="noreferrer"><span>Twitter</span> <TwitterBlack /></a></li>
                <li><a href={bottomContent?.Facebook_Link} target="_blank"  rel="noreferrer"><span>Facebook</span> <FacebookBlack /></a></li>
                <li><a href={bottomContent?.Instagram_Link} target="_blank"  rel="noreferrer"><span>Instagram</span> <InstagramBlack /></a></li>
                <li><a href={bottomContent?.Linkedin_Link} target="_blank"  rel="noreferrer"><span>Linkedin</span> <LinkedinBlack /></a></li>
              </ul>
            </div>
          <div className="copy text-18">
            <ul >
              <li><Link className='text-18' to="/terms-and-condition/">Terms</Link><span className="link-separator">|</span></li>
              <li><Link className='text-18' to="/privacy-policy/">Privacy Policy</Link><span className="link-separator">|</span></li>
              <li><Link className='text-18'to="/cookie-policy/">Cookie Policy</Link><span className="link-separator">|</span></li>
              <li><Link className='text-18' to="/sitemap/">Sitemap</Link><span className="link-separator">|</span></li>
              <li><a className='text-18' target="_blank" href="https://ggfx-anthonypepe.s3.eu-west-2.amazonaws.com/i.prod/Client_Money_Protection_Security_Certificate_Anthony_Pepe_a77bd5700e.pdf">CMP Security </a><span className="link-separator">|</span></li>
              <li><a className='text-18' target="_blank"  href="https://ggfx-anthonypepe.s3.eu-west-2.amazonaws.com/i.prod/Propertymark_CMP_Main_Scheme_Certificate_7e68dbde43.pdf">Client Money Protection</a><span className="link-separator">|</span></li>
              <li><a className='text-18' target="_blank" href="https://www.propertymark.co.uk/professional-standards/rules.html">Propertymark Conduct and Membership Rules</a></li>
            </ul>
            <p className="copy text-18">Charles Harris Ltd. Trading as Anthony Pepe No. 05225924</p>
            <p className="copy text-18 spacing-bottom-32">© {new Date().getFullYear()} Anthony Pepe. All Rights Reserved.</p>
            <p className="site-by text-18 m-0">
              Site by <a href="https://starberry.tv/" target="_blank">Starberry</a>
            </p>
          </div>
          </div>
          <div className="footer-bottom-right">
            <ul>
                <li><img src={PRSLogo} alt="PRS Logo" /></li>
                <li><img src={ARLAPMLogo} alt="Arla Property Mark Logo"/></li>
                <li><img src={NAEAPMLogo} alt="Naea Property Mark Logo"/></li>
            </ul>
          </div>
    </div>
  );
};

export default FooterBottom;